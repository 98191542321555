.react-datepicker-wrapper {
  width: 100%;
}

#dixa-iframe-wrapper {
  position: relative;
  z-index: 1299; /* MuiDialog and MuiPopover are 1300, we dont want to show dixa over mui dialogs or any popovers like dropdown options */
}

/*
A workaround to show the last 0 in odometerjs
https://github.com/HubSpot/odometer/issues/106
*/
.odometer .odometer-inside .odometer-digit:last-of-type {
  display: none;
}

.odometer .odometer-inside {
  display: inline-flex;
}

.odometer .odometer-inside:before {
  content: '$';
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

/* Image gallery */
.image-gallery {
  background-color: transparent;
}
.image-gallery-slide-wrapper {
  border: 1px solid #eaeaea;
  margin-bottom: 24px;
  background-color: white;
}

.fullscreen .image-gallery-slide-wrapper {
  margin-bottom: 0;
}

.image-gallery-thumbnail.active,
.image-gallery-thumbnail:hover,
.image-gallery-thumbnail:focus {
  border: 1px solid #203050;
}

.image-gallery-thumbnail + .image-gallery-thumbnail {
  background-color: #e8eaee;
}

.only-print {
  display: none;
}

@media print {
  .dont-print {
    display: none;
  }

  .only-print {
    display: block;
  }

  .display-block-on-print {
    display: block !important;
  }

  .dont-break {
    break-inside: avoid;
  }
}
